<div *ngIf="isNominationAllowed" class="card-wrapper">
    <div class="row card-group">
        <div class="col-lg-2 col-md-6 col-sm-12 col-12"></div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch">
            <div class="card">
                <div class="card-body">
                    <div class="">
                        <div class="">
                            <img src="../../../assets/img/rising-star-1.png"  alt="Rising Star"
                                class="img-fluid"/>
                        </div>
                        <div class="col">
                            <div class="card-block">
                                <!-- <h1 class="card-title">Rising Star</h1> -->
                                <div class="card-description" style="height:24px"></div>
                                <p class="card-text">This is DMI's Rookie of the Year award that 
                                recognizes the non-management level new employee 
                                (less than 18 months with DMI) who has made the 
                                greatest impact to the company's success.</p>
                                <button type="button" class="btn btn-primary"
                            (click)="nominateEmployee('RISING STAR')" *ngIf="isNominationAllowed">Submit Nomination</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch">
            <div class="card">
                <div class="card-body">
                    <div class="">
                        <div class="">
                            <img src="../../../assets/img/all-star-1.png" alt="All Star"  class="img-fluid" />
                        </div>
                        <div class="col">
                            <div class="card-block">
                                <!-- <h1 class="card-title">All Star</h1> -->
                                <div class="card-description" style="height:24px"></div>
                                <p class="card-text">This Award is presented to a mentor that 
                                    helped shape, inspire, and motivate their 
                                    team. A good mentor/leader will promote 
                                    intellectual growth and development, 
                                    career and professional guidance, 
                                    while being a positive role model.
                                </p>
                                <button type="button" class="btn btn-primary"
                                    (click)="nominateEmployee('ALL STAR')" *ngIf="isNominationAllowed">Submit Nomination</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 col-12"></div>
    </div>
    <div class="row card-group">
        <div class="col-lg-2 col-md-6 col-sm-12 col-12"></div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch">
            <div class="card">
                <div class="card-body">
                    <div class="">
                        <div class="">
                            <img src="../../../assets/img/carpe-dm-1.png" alt="Carpe DM"  class="img-fluid" />
                        </div>
                        <div class="col">
                            <div class="card-block">
                                <!-- <h1 class="card-title">CarpeDM</h1> -->
                                <div class="card-description" style="height:24px"></div>
                                <p class="card-text">This Award is given to an employee that had an 
                                    innovative idea, took the initiative to 
                                    run with it, and seized the moment to produce 
                                    results. They are a thought leader whose 
                                    innovative solution or concept made a big impact 
                                    for DMI. Award winners may have won for creating a 
                                    unique winning proposal solution, for solving a highly 
                                    challenging customer issue or for creating a new 
                                    technical offering.
                                </p>
                                <button type="button" class="btn btn-primary btn-success"
                                    (click)="nominateEmployee('CARPEDM')" *ngIf="isNominationAllowed">Submit Nomination</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch">
            <div class="card">
                <div class="card-body">
                    <div class="">
                        <div class="">
                            <img src="../../../assets/img/mvp-1.png" alt="MVP"  class="img-fluid" />
                        </div>
                        <div class="col">
                            <div class="card-block">
                                <!-- <h1 class="card-title">MVP</h1> -->
                                <h4 class="card-description">DMI’s Employee of the Year</h4>
                                <p class="card-text">This Award is DMI’s Employee of the Year. 
                                    It is presented to the individual who has 
                                    gone above and beyond as a DMI employee. 
                                    This person embodies our corporate values 
                                    in their daily work and has proven their 
                                    dedication to customers, clients, business 
                                    partners and/or co-workers. 
                                    They have shown professionalism and pervasive 
                                    excellence in all they do and has taken the 
                                    initiative to demonstrate value beyond expectations
                                     or the requirements of a project, and in doing so, 
                                     has won the admiration and gratitude of clients and 
                                     colleagues alike. A Gold MVP winner will be selected 
                                     as well as Silver/Bronze honorable MVP’s.
                                </p>
                                <button type="button" class="btn btn-primary"
                                 (click)="nominateEmployee('MVP')" *ngIf="isNominationAllowed">Submit Nomination
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 col-12">
        </div>
    </div>
</div>