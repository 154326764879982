import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NominationService {
  private _token: string;
  private _loginUrl: string = "/api/do-login";
  private _setUserUrl: string = "/api/setUser";
  private _signUpUrl: string = "/api/user";
  private _allUsersDataUrl: string = "/api/get-data";
  private _addEmployeeNominationUrl: string = "/api/add-employee-nomination";
  private _addTeamNominationUrl: string = "/api/add-team-nomination";
  private _getAllNominatedEmployeeUrl: string = "/api/get-all-employee-nomination";
  private _getNominatedEmployeeUrl: string = "/api/get-employee-nomination";
  private _getEmpNominationReview: string = "/api/get-emp-nomination-review";
  private _getAllNominatedTeamUrl: string = "/api/get-all-team-nomination";
  private _getAllBuListUrl: string = "/api/get-bu-list";
  private _getAllAwardListUrl: string = "/api/get-award-list";
  private getEmpByAwardId: string = "/api/get-employee-nomination-by-awardId";
  private getCertificateDataAwardId: string = "/api/get-certificate-data-by-awardId";
  private getEmpByAwardIdForJury: string = "/api/get-employee-nomination-by-awardId-for-jury";
  private getTeamByAwardId: string = "/api/get-team-by-awardId";
  private getCertificateDataAwardIdForTeam: string = "/api/get-certificate-data-by-awardId-for-team";
  private getTeamByAwardIdForJury: string = "/api/get-team-by-awardId-for-jury";
  private resetPassword: string = "/api/reset-password";
  private changeLoginRole: string = "/api/user/change-login-role";
  private fileDownload: string = '/api/file-downloads';
  private getEmpsByNominationId = '/api/get-employee-by-nominationId';
  private getTeamByNominationId = '/api/get-team-by-nominationId';
  private addEmpReviewDetails = '/api/create-emp-review';
  private getUsersCommentByNominationId = '/api/get-users-review-comments';
  private getUsersCommentForTeam = '/api/get-users-review-comments-for-team';
  private reviewRatingByaward = '/api/get-emp-review-by-awardId';
  private getReviewInfoByAwardId = `/api/get-review-info-by-awardId`;
  private getTeamReviewInfoByAwardId = `/api/get-team-review-by-awardId`;
  private getAllJury = '/api/find-all-jury';
  private addTeamReview = `/api/create-team-review`;
  private reSubmitNominatedBy = `/api/resubmit-nomination`;
  private reSubmitTeamNominatedBy = `/api/resubmit-team-nomination`;
  private getTeamReviewByUserIdAndAwardId = `/api/get-team-review-by-awardId-userid`;
  private updateUserReviewDetails = `/api/update-review-details-by-userid`;

  private updateUserReviewForTeam = `/api/update-team-review-details-by-userid`;
  private sendCertificatetoWinner = `/api/send-certificate-to-user-via-email`
  private isSubMenuHidden = new BehaviorSubject<boolean>(true);
  private isSelectedYear = new BehaviorSubject<string>("Current Year");
  private updateWinnerIndividual = `/api/update-winner-details-individual`;
  private updateWinnerForTeam = `/api/update-winner-details-team`;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  setValueToLocalStorage(key, userToke) {
    this.localStorageService.set(key, userToke);
  }
  getValueToLocalStorage(key) {
    return this.localStorageService.get(key);
  }
  removeValueToLocalStorage(key) {
    this.localStorageService.remove(key);
  }
  removeUserInfo() {
    this.localStorageService.remove("auth-token");
    this.localStorageService.remove("user_role");
    this.localStorageService.remove("id");
    this.localStorageService.remove("email");
    this.localStorageService.remove("last_login_as");
    this.localStorageService.remove("full_name");
  }

  isAdminUserRole(): boolean {
    let empRole = '';
    empRole = this.localStorageService.get("user_role");
    return empRole === 'admin';
  }

  isSupeUserRole(): boolean {
    let empRole = '';
    empRole = this.localStorageService.get("user_role");
    return empRole === 'super-user';
  }

  isAdminSuperUserRole(): boolean {
    let empRole = '';
    empRole = this.localStorageService.get("user_role");
    return empRole === 'admin-superAdmin';
  }


  validateUserLogin(userLogin): Observable<any> {
    return this.http.post(this._loginUrl, userLogin);
  }

  setUser(user: any): Observable<any> {
    return this.http.post(this._setUserUrl, user);
  }

  addnewUser(userSignUp): Observable<any> {
    return this.http.post(this._signUpUrl, userSignUp);
  }

  resetUserPassword(userInfo): Observable<any> {
    return this.http.put(this.resetPassword, userInfo);
  }

  setAuthHeader() {
    const authKey = this.getValueToLocalStorage("auth-token");
    const headerDict = {
      'auth-token': authKey
    }
    return {
      headers: new HttpHeaders(headerDict),
    };
  }

  addEmployeeNomination(employeeNominationData): Observable<any> {
    console.log("employeeNominationData", employeeNominationData);
    return this.http.post(this._addEmployeeNominationUrl, employeeNominationData, this.setAuthHeader());
  }
  addTeamNomination(teamNominationData): Observable<any> {
    return this.http.post(this._addTeamNominationUrl, teamNominationData, this.setAuthHeader());
  }

  getAllUsersData(): Observable<any> {
    return this.http.get(this._allUsersDataUrl, this.setAuthHeader());
  }
  getAllNominatedEmployee(): Observable<any> {
    return this.http.get(this._getAllNominatedEmployeeUrl, this.setAuthHeader());
  }

  getNominatedEmployee(): Observable<any> {
    return this.http.get(this._getNominatedEmployeeUrl, this.setAuthHeader());
  }

  getEmpNominationReview(): Observable<any> {
    return this.http.get(this._getEmpNominationReview, this.setAuthHeader());
  }

  getNominatedEmployeeByAwardId(awardId): Observable<any> {
    return this.http.get(this.getEmpByAwardIdForJury + `/${awardId}`, this.setAuthHeader());
  }

  getNominatedEmployeeByAwardIdForAdmin(awardId, selectedYear): Observable<any> {
    return this.http.get(this.getEmpByAwardId + `/${awardId}/${selectedYear}`, this.setAuthHeader());
  }
  getCertificateDataAwardIdForAdmin(awardId, selectedYear): Observable<any> {
    return this.http.get(this.getCertificateDataAwardId + `/${awardId}/${selectedYear}`, this.setAuthHeader());
  }

  changeLoginUserRole(userInfo): Observable<any> {
    const userId = userInfo.loginUserId;
    const loginRole = userInfo.login_role;
    return this.http.get(this.changeLoginRole + `/${userId}/${loginRole}`, this.setAuthHeader());
  }
  getNominatedTeamByAwardIdForAdmin(awardId, selectedYear): Observable<any> {
    return this.http.get(this.getTeamByAwardId + `/${awardId}/${selectedYear}`, this.setAuthHeader());
  }
  getCertificateDataAwardIdForTeamForAdmin(awardId, selectedYear): Observable<any> {
    return this.http.get(this.getCertificateDataAwardIdForTeam + `/${awardId}/${selectedYear}`, this.setAuthHeader());
  }
  getNominatedTeamByAwardId(awardId): Observable<any> {
    return this.http.get(this.getTeamByAwardIdForJury + `/${awardId}`, this.setAuthHeader());
  }
  getAllNominatedTeam(): Observable<any> {
    return this.http.get(this._getAllNominatedTeamUrl, this.setAuthHeader());
  }

  getAllJuryList(): Observable<any> {
    return this.http.get(this.getAllJury, this.setAuthHeader());
  }
  // nomination id
  getEmpDetailsByNominationId(nominationId): Observable<any> {
    return this.http.get(this.getEmpsByNominationId + `/${nominationId}`, this.setAuthHeader());
  }
  // nomination id
  getTeamDetailsByNominationId(nominationId): Observable<any> {
    return this.http.get(this.getTeamByNominationId + `/${nominationId}`, this.setAuthHeader());
  }

  getAllBUList(): Observable<any> {
    return this.http.get(this._getAllBuListUrl, this.setAuthHeader());
  }
  getAllAwardList(): Observable<any> {
    return this.http.get(this._getAllAwardListUrl, this.setAuthHeader());
  }

  getDownloadFiles(fileName: string): Observable<any> {
    const authHeaders = this.setAuthHeader();
    const resType = { responseType: 'blob' as 'json' };
    const resHeaders = { ...this.setAuthHeader(), ...resType };
    console.log("resHeaders..", resHeaders);
    return this.http.get(this.fileDownload + `/${fileName}`, resHeaders);
  }

  public getSubNavMenuStatus(): Observable<boolean> {
    return this.isSubMenuHidden.asObservable();
  }

  public getSelectedYear(): Observable<string> {
    return this.isSelectedYear.asObservable();
  }

  // review details
  createEmpReviewDetails(reviewDetails): Observable<any> {
    return this.http.post(this.addEmpReviewDetails, reviewDetails, this.setAuthHeader());
  }

  getUsersComments(nominationId, userId = 0): Observable<any> {
    return this.http.get(this.getUsersCommentByNominationId + `/${nominationId}/${userId}`, this.setAuthHeader());
  }

  getUsersCommentsForTeam(nominationId, userId = 0): Observable<any> {
    return this.http.get(this.getUsersCommentForTeam + `/${nominationId}/${userId}`, this.setAuthHeader());
  }

  getReviewRatingByAwardIdAndUserid(awardId, userId): Observable<any> {
    return this.http.get(this.reviewRatingByaward + `/${awardId}/${userId}`, this.setAuthHeader());
  }

  getTeamReviewByUserAndAward(awardId, userId): Observable<any> {
    return this.http.get(this.getTeamReviewByUserIdAndAwardId + `/${awardId}/${userId}`, this.setAuthHeader());
  }

  getReviwInfoByAwardId(awardId, selectedYear): Observable<any> {
    return this.http.get(this.getReviewInfoByAwardId + `/${awardId}/${selectedYear}`, this.setAuthHeader());
  }

  reSubmitNomination(nominatorDetails: any): Observable<any> {
    console.log(nominatorDetails);
    return this.http.post(this.reSubmitNominatedBy, nominatorDetails, this.setAuthHeader());
  }

  reSubmitTeamNomination(nominatorDetails: any): Observable<any> {
    return this.http.post(this.reSubmitTeamNominatedBy, nominatorDetails, this.setAuthHeader());
  }

  getTeamReviwByAwardId(awardId, selectedYear): Observable<any> {
    return this.http.get(this.getTeamReviewInfoByAwardId + `/${awardId}/${selectedYear}`, this.setAuthHeader());
  }

  createTeamReviewDetails(reviewDetails): Observable<any> {
    return this.http.post(this.addTeamReview, reviewDetails, this.setAuthHeader());
  }

  updateUserReviewDetailsForemp(reviewDetails) {
    return this.http.post(this.updateUserReviewDetails, reviewDetails, this.setAuthHeader());
  }

  updateUserReviewDetailsForTeam(reviewDetails) {
    return this.http.post(this.updateUserReviewForTeam, reviewDetails, this.setAuthHeader());
  }
  setSubNavMenuStatus(status: boolean): void {
    this.isSubMenuHidden.next(status);
  }

  setSelectedYear(status: string): void {
    this.isSelectedYear.next(status);
  }

  setWinnerIndividual(details) : Observable<any> {
    return this.http.post(this.updateWinnerIndividual, details, this.setAuthHeader())
  }

  setWinnerIndividualForTeam(details): Observable<any> {
    return this.http.post(this.updateWinnerForTeam, details, this.setAuthHeader())
  }

  createCertificateAndSend(details): Observable<any>{
    return this.http.post(this.sendCertificatetoWinner, details, this.setAuthHeader())
  }

}
