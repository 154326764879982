<nav class="navbar navbar-inverse dmi-home-nav">
    <div class="container-fluid">
    </div>
</nav>
<div class="login-form">
    <div class="message-wrapper">
        <div class="alert alert-success animate__animated animate__bounceIn" *ngIf="showSuccess">
            {{successMsg}}
        </div>
        <div class="alert alert-danger animate__animated animate__bounceIn" *ngIf="showError">
            {{errorMsg}}
        </div>
    </div>
    <form [formGroup]="resetPasswordForm">
        <div class="avatar">
            <img src="../../assets/img/dmi-login-header-1.png" alt="dmi-logo" srcset="" width=220px
             height=100px>
        </div>
        <h4 class="modal-title">Reset Password</h4>
        <div class="form-group">
            <input type="text" formControlName="useremail" placeholder="Registered email id" class="form-control"   [ngClass]="{ 'is-invalid': submitted && f.useremail.errors }" />
            <div *ngIf="submitted && f.useremail.errors" class="invalid-feedback">
                <div *ngIf="f.useremail.errors.required">Registered Email is required</div>
                <div *ngIf="f.useremail.errors.email">Email must be a valid email address</div>
                <div *ngIf="f.useremail.errors.pattern">Email address must be a valid DMI email Id</div>
            </div>
        </div>
        <div class="form-group">
            <input type="password" placeholder="New Password" formControlName="password" class="form-control"   [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
        </div>
        <div class="form-group">
            <input type="password" placeholder="Confirm Password" formControlName="confirmedPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmedPassword.errors }" />
            <div *ngIf="submitted && f.confirmedPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmedPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmedPassword.errors.notEquivalent">Password does not match.</div>
            </div>
        </div><br>
        <input type="button" class="btn btn-primary btn-block btn-lg" [disabled]="toogleSubmit" (click)="getRestPasswordLink()"
            value="Reset Password"><br>
        <div class="text-center small sign-in-link"><a routerLink="/login" class="btn btn-link">Sign in instead</a>
        </div>
    </form>
</div>