export abstract class NominationConstants {
    // nomination allowed
    static readonly IS_NOMINATION_ALLOWED: boolean = true;
    // message type
    static readonly ERROR_MESSAGE: string = 'error';
    static readonly SUCCESS_MESSAGE: string = 'success';
    // file upload placeholder
    static readonly DOC_FILE_UPLOAD_PLACEHOLDER = 'Upload';
    static readonly AUDIO_VIDEO_FILE_UPLOAD_PLACEHOLDER = 'Upload';
    static readonly PHOTO_FILE_UPLOAD_PLACEHOLDER = 'Upload';
    static readonly VIDEO_FILE_UPLOAD_PLACEHOLDER = 'Upload';
    // file upload types 
    static readonly VIDEO_FILE_TYPE: string = 'Video';
    static readonly AUDIO_FILE_TYPE: string = 'Audio';
    static readonly DOC_FILE_TYPE: string = 'Document';

    // file size units
    static readonly KB_FILE_UNIT: string = 'kb';
    static readonly BYTES_FILE_UNIT: string = 'bytes';
    static readonly MB_FILE_UNIT: string = "Mb";

    // file size limit
    static readonly DOC_FILE_SIZE_LIMIT: number = 10;
    static readonly AUDIO_FILE_SIZE_LIMIT: number = 15;
    static readonly PHOTO_FILE_SIZE_LIMIT: number = 10;
    static readonly VIDEO_FILE_SIZE_LIMIT: number = 30;

    //list of properties name
    static readonly ID: string = 'id';
    static readonly USER_ID: string = 'userId';
    static readonly ID_FIELD: string = 'id';
    static readonly TEAM_NAME: string = 'team_name';
    static readonly EMP_NAME: string = 'emp_name';
    static readonly AWARD_LIST: string = 'award_list';
    static readonly AWARD_ID: string = 'awardId';
    static readonly USER: string = 'user';
    static readonly INSERTED_RECORD_ID: string = 'recordId';
    static readonly AUDIO_FILE: string = 'audiofile';
    static readonly VIDEO_FILE: string = 'videofile';
    static readonly DOC_FILE: string = 'file';
    static readonly FILE_UNIQUE_ID:string = 'filePreFixId';

    // EMPLOYEE table default header for admin
    static readonly EMP_TABLE_DEFAULT_HEADER = ['#','Employee Name', 'Email Id',
        'Business unit (BU)', 'Citation', 'Nominated By',
        'Media', 'Document'];
        
    // TEAM table default header for admin
    static readonly TEAM_TABLE_DEFAULT_HEADER = ['#','Team Name', 'Employees Name','Citation',
        'Nominated By', 'Media', 'Document'];

    // List of all supported countries
    static readonly COUNTRY_LIST = ['Argentina', 'Canada', 'Italy', 'India', 'Spain', 'United Kingdom', 'United States']

    // allow file format for audio,video and document
    static readonly DOC_FILE_ALLOW_TYPES: string[] = ["pdf", "docx", "doc", "ppt", "pptx"];
    static readonly VIDEO_FILE_ALLOW_TYPES: string[] = ["mp4", "mov", "wmv", "flv", "avi", "ogg"];
    static readonly AUDIO_FILE_ALLOW_TYPES: string[] = ["m4a", "ffac", "mp3", "mp4", "wav", "mov", "wmv", "flv", "avi","ogg"];
    static readonly PHOTO_FILE_ALLOW_TYPES : string[] = ["jpg", "jpeg", "png"];
    //callback messages
    static readonly messages = {
        "ERROR": {
            FILE_SIZE_ERROR: "File size must be less then xxxx",
            FILE_TYPE_ERROR: "Ony xxxx file can be uploaded",
        },
        "SUCCESS": {
            NOMINATION_FORM_DATA: "Nomination submitted successfully.",
            FILE_UPLOAD: "Success! successfully uploaded file!"
        }
    }

    // Apis list
    static readonly APIs = {
        "POST": {
            VIDEO_UPLOAD: 'video-upload',
            AUDIO_UPLOAD: 'audio-upload',
            PHOTO_UPLOAD : 'photo-upload',
            FILE_UPLOAD: 'file-upload',
            USER_LOGIN: '/api/do-login',
            USER_SIGNUP: '/api/user',
            ADD_EMP_NOMINATION: '/api/add-employee-nomination',
            ADD_TEAM_NOMINATION: '/api/add-team-nomination',
        },
        "GET": {
            ALL_USERS_INFO: '/api/get-data',
            ALL_EMP_NOMINATION: '/api/get-all-employee-nomination',
            ALL_TEAM_NOMINATION: '/api/get-all-team-nomination',
            ALL_BU_NAME: '/api/get-bu-list',
            ALL_AWARDS_NAME: '/api/get-award-list'

        }
    }
    
    static readonly CURRENT_EVN = 'PROD';
    static readonly BASEUri = {
        "DEV": "http://localhost:3030/",
        "PROD": "https://annualawards.dminc.com/"
    }
}