export class NominationFormDataParser {
    constructor() { }
    public static Employee(formEntry) {
        return {
            user_id: parseInt(formEntry.userId),
            emp_nomination_client_id: formEntry.emp_nomination_client_id,
            user_email: formEntry.user_email_id,
            award_id: parseInt(formEntry.awardId),
            emp_name: formEntry.name,
            emp_email_id: formEntry.email,
            dmi_location: formEntry.location,
            bu_id: parseInt(formEntry.bu),
            citation: formEntry.citation,
            doc_file_name: formEntry.docFile,
            audio_file_name: formEntry.audio_file_name,
            photo_file_name:  formEntry.photoFile
        };
    }

    public static Team(formEntry) {
        return {
            user_id: parseInt(formEntry.userId),
            team_nomination_client_id: formEntry.team_nomination_client_id,
            user_email: formEntry.user_email_id,
            award_id: parseInt(formEntry.awardId),
            team_name: formEntry.teamName,
            employees_name: formEntry.empName,
            citation: formEntry.citation,
            doc_file_name: formEntry.docFile,
            audio_file_name: formEntry.audio_file_name,
            photo_file_name:  formEntry.photoFile
        };
    }
}