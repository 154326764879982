<div class="col-lg-6 col-md-6 col-sm-12 offset-md-3 award-entry-form">
    <div class="row">
        <div class="message-wrapper">
            <div class="alert alert-success animate__animated animate__bounceIn" *ngIf="showSuccess">
                {{successMsg}}
            </div>
            <div class="alert alert-danger animate__animated animate__bounceIn" *ngIf="showError">
                {{errorMsg}}
            </div>
        </div>
        <div class="entry-form-title">
            <h2>Nomination for <b>{{awardNameDisplay}}</b></h2>
        </div>
    </div>
    <hr>
    <div class="">
        <div class="">
            <form [formGroup]="awardEntryForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label>Nominated Employee's Name<span class="require-field">*</span></label>
                    <input type="text" formControlName="name" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Nominated Employee's DMI Email Address<span class="require-field">*</span></label>
                    <input type="text" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        <div *ngIf="f.email.errors.pattern">Email address must be a valid DMI email Id</div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label>Nominated Employee's Location (Country)<span class="require-field">*</span></label>
                        <select formControlName="location" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
                            <option [ngValue]="null">Select Category</option>
                            <option *ngFor="let location of countries">
                                {{location}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
                            <div *ngIf="f.location.errors.required">Country is required</div>
                        </div>
                    </div>
                  
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label>Nominated Employee's Team<span class="require-field">*</span></label>
                        <select formControlName="bu" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.bu.errors }">
                            <option [ngValue]="null">Select Category</option>
                            <option *ngFor="let bu of buNameList" [ngValue]="bu.id">
                                {{bu.bu_name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.bu.errors" class="invalid-feedback">
                            <div *ngIf="f.bu.errors.required">BU is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Citation (brief description)<span class="require-field">*</span></label>
                    
                    <textarea rows="4" cols="50" maxlength="2000"  formControlName="citation" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.citation.errors }" ></textarea>
                    <div *ngIf="submitted && f.citation.errors" class="invalid-feedback">
                        <div *ngIf="f.citation.errors.required">Citation is required</div>
                    </div>
                </div>
            <fieldset class="border p-2" *ngIf="isNominationAllowed">
                <legend class="doc-fieldset-title">Supporting file(s):</legend>
                <div class="form-group">
                    <label>Nominee Photo<span class="require-field"></span></label>
                    <div class="input-group">
                        <div class="custom-file upload-audio-video">
                            <input type="file" #photoFileInput formControlName="photo"
                                class="custom-file-input form-control" id="photo-file" aria-describedby="inputGroupFileAddon01"
                                (change)="onPhotoFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.photo.errors }">
                            <label class="custom-file-label" for="audio-file">{{photoFileUpload}}</label>
                            <div *ngIf="submitted && f.photo.errors" class="invalid-feedback">
                                <div *ngIf="f.photo.errors.required">Nominee Photo is required</div>
                            </div>
                        </div>
                        
                    </div>
                    <span>Supporting file formats 
                        <i>"jpeg", "jpg", "png"</i>
                    </span>
                    <br/>
                    <span>Maximum File Size:
                        <i>10MB</i>
                    </span>
                </div>

                <div class="form-group">
                    <label>Audio/Video<span class="help-field"> (preferred but not essential)</span></label>
                    <div class="input-group">
                        <div class="custom-file upload-audio-video">
                            <input type="file" #audioOrVideoFileInput formControlName="audioOrvideo"
                                class="custom-file-input form-control" id="audio-video-file" aria-describedby="inputGroupFileAddon01"
                                (change)="onAudioVideoFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.audioOrvideo.errors }">
                            <label class="custom-file-label" for="audio-file">{{audioOrVideoFileUpload}}</label>
                            <!-- <div *ngIf="submitted && f.audioOrvideo.errors" class="invalid-feedback">
                                <div *ngIf="f.audioOrvideo.errors.required">Audio or Video is required</div>
                            </div> -->
                        </div>
                        
                    </div>
                    <!-- <span>
                        <i class="fa fa-info-circle info-icon" aria-hidden="true"></i>
                        <i class = "photo-text">Audio or video should be atleast of 60 seconds</i>
                    </span> -->
                    <!-- <br/> -->
                    <span>Supporting file formats: 
                        <i>"m4a", "ffac", "mp3", "mp4", "wav", "mov", "wmv", "flv", "avi", "ogg"</i>
                    </span>
                    <!-- <br/> -->
                    <!-- <span>Maximum file size:
                        <i>
                            Video - 30MB, Audio - 15MB
                        </i>
                    </span> -->
                </div>
                <div class="form-group">
                    <label>Document</label>
                    <div class="input-group">
                        <div class="custom-file">
                            <input type="file" formControlName="docFile" #docFileInput name="docFile" class="custom-file-input"
                                id="doc-file" aria-describedby="inputGroupFileAddon02" (change)="onDocFileChange($event)">
                            <label class="custom-file-label" for="doc-file">{{docFileUpload}}</label><br> 
                        </div>
                    </div><br>
                    <span>Supporting file formats: 
                        <i>"pdf", "docx", "doc", "ppt", "pptx"</i>
                    </span>
                    <br/>
                    <span>Maximum File Size:
                        <i>10MB</i>
                    </span>
                </div>
                <!-- <div class="form-group">
                    <div class="input-group">
                        <div class="custom-file">
                            <input type="file" #videoFileInput class="custom-file-input" id="video-file"
                                aria-describedby="inputGroupFileAddon03" (change)="onVideoFileChange($event)">
                            <label class="custom-file-label" for="audio-file">{{videoFileUpload}}</label>
                        </div>
                    </div>
                </div> -->
            </fieldset><br>
            <div class="row ml-1">
                <div class="mr-2">
                    <button class="btn btn-primary" [disabled]="toogleSubmit" type="submit" *ngIf="isNominationAllowed">Submit</button>
                </div>
                <div>
                    <!-- <button class="btn btn-primary" type="button" (click)="clearForm()" *ngIf="isNominationAllowed">Add new
                        Nomination</button> -->
                </div>
            </div>
            </form>
        </div>
    </div>
</div>