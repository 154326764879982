import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { NominationService } from './shared/services/nomination.service';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { HomeComponent } from './home/home.component';
import { NominateComponent } from './home/nominate/nominate.component';
import { EmployeeComponent } from './home/employee/employee.component';
import { TeamComponent } from './home/team/team.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './security/auth.guard';
import { TeamNominationComponent } from './home/team-nomination/team-nomination.component';
// TODO nr.1: Add Azure dependencies for authentication.
import {
  MsalModule,
  MsalInterceptor,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
  MsalService,
  MsalAngularConfiguration
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Configuration } from 'msal';
import { NominationCompletedComponent } from './nomination-completed/nomination-completed.component';
// import { ModalComponent } from './shared/components/modal/modal.component';

// TODO nr.2: Define protected resource URIs
/**
 * MSAL Angular provides an Interceptor class that automatically acquires
 * tokens for outgoing requests that use the Angular http client to known protected resources.
 */
 export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

// TODO nr.3 (optional): Add this line if you want to support Internet Explorer
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// TODO nr.4: Add first configuration function for Azure AD auth
function MSALConfigFactory(): Configuration {
  return {
    auth: {
      // Client ID of the application you want to authenticate to (e.g. 6226576d-37e9-49eb-b201-ec1eeb0029b6)
      clientId: 'c3e0759a-8f5b-452a-a90d-1b9d66b4921b',
      /**
       * In order to support sign-ins with work and school accounts as well as personal Microsoft accounts,
       * set your authority to use the common endpoint i.e. https://login.microsoftonline.com/common
       *
       * If only organisation specific accounts need access to the application use tenant endpoint.
       * Get your tenant ID from here: https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/Overview
       * e.g https://login.microsoftonline.com/cf132d25-305c-475f-915d-80062915efbe
       */
      authority: 'https://login.microsoftonline.com/db7ac9ef-779d-46e5-9bca-00509580ad6b',
      validateAuthority: true,
      // Must be the same URI as the one configured in Azure AD.
      // redirectUri: 'http://localhost:4200/',
      // postLogoutRedirectUri: 'http://localhost:4200/',
      redirectUri: 'https://annualawards.dminc.com/',
      postLogoutRedirectUri: 'https://annualawards.dminc.com/',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  };
}

// TODO nr.6: Add another config which contains the following: consent scopes, unprotected and protected routes, etc.
function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: !isIE,
    consentScopes: [
      // Define consent scopes, so you can access user information over the Microsoft GRAPH API for example.
      'user.read',
      'openid',
      'profile',
      // This is something we don't know yet. We will add this after defining the Node.js API in Azure.
      'api://c3e0759a-8f5b-452a-a90d-1b9d66b4921b'
    ],
    // Token will not be send to the unprotected URIs
    unprotectedResources: ['https://www.microsoft.com/en-us/'],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ForgetPasswordComponent,
    HomeComponent,
    NominateComponent,
    EmployeeComponent,
    TeamComponent,
    PageNotFoundComponent,
    TeamNominationComponent,
    NominationCompletedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    MsalModule,
    NgMultiSelectDropDownModule.forRoot(),

  ],
  providers: [NominationService, AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
