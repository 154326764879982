import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NominationService } from '../../shared/services/nomination.service';
import { NominationFormDataParser } from '../nomination.parse';
import { NominationConstants } from '../../shared/constants/nomination.constant';
import { NominationUtility } from '../../shared/utilities/nomination-utility';

@Component({
  selector: 'app-nominate',
  templateUrl: './nominate.component.html',
  styleUrls: ['./nominate.component.scss']
})
export class NominateComponent implements OnInit, AfterViewInit {
  public isNominationAllowed: boolean = false;
  public showSuccess: boolean = false;
  public showError: boolean = false;
  public successMsg: string = "";
  public errorMsg: string = "";
  public supportingDocument: boolean = true;
  public recordId: number;
  public awardEntryForm: FormGroup;
  public submitted: boolean = false;
  public subScribeUrl: Subscription;
  public awardNameDisplay: string;
  public buNameList;
  public docUploadFile;
  public countries;
  public duration;
  public docFileUpload: string = "";
  public audioOrVideoFileUpload: string = "";
  public photoFileUpload: string = "";
  public videoFileUpload: string = "";
  public toogleSubmit: boolean = false;
  public isFileUploadDisabled: boolean = true;

  private timeOut: number;
  private timerDelay: number = 3000;
  private nominationUniqueId: string;
  private loggedInUserId;
  private loggedInUserEmail: string;

  private readonly docAllowType: string[] = NominationConstants.DOC_FILE_ALLOW_TYPES;
  private readonly videoAllowType: string[] = NominationConstants.VIDEO_FILE_ALLOW_TYPES;
  private readonly audioAllowType: string[] = NominationConstants.AUDIO_FILE_ALLOW_TYPES;
  private readonly photoAllowType: string[] = NominationConstants.PHOTO_FILE_ALLOW_TYPES;

  private readonly audioUpload: string = NominationConstants.APIs.POST.AUDIO_UPLOAD;
  private readonly photoUpload: string = NominationConstants.APIs.POST.PHOTO_UPLOAD;
  private readonly fileUpload: string = NominationConstants.APIs.POST.FILE_UPLOAD;
  private readonly DOC_FILE_SIZE_LIMIT: number = NominationConstants.DOC_FILE_SIZE_LIMIT;
  private readonly AUDIO_FILE_SIZE_LIMIT: number = NominationConstants.AUDIO_FILE_SIZE_LIMIT;
  private readonly VIDEO_FILE_SIZE_LIMIT: number = NominationConstants.VIDEO_FILE_SIZE_LIMIT;

  private readonly PHOTO_FILE_SIZE_LIMIT: number = NominationConstants.PHOTO_FILE_SIZE_LIMIT;

  private baseUri: string = NominationConstants.BASEUri[NominationConstants.CURRENT_EVN];

  public empNominee: string;
  public empNomineeID: number;
  public mediaDurationMsg:string = 'Audio or Video should be atleast of 60 seconds';



  @ViewChild('docFileInput', { static: false }) docFileInput: ElementRef;
  @ViewChild('audioOrVideoFileInput', { static: false }) audioOrVideoFileInput: ElementRef;
  @ViewChild('photoFileInput', { static: false }) photoFileInput: ElementRef;
  @ViewChild('videoFileInput', { static: false }) videoFileInput: ElementRef;

  constructor(public formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute, private nominationService: NominationService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.isNominationAllowed = NominationConstants.IS_NOMINATION_ALLOWED;
    this.countries = NominationConstants.COUNTRY_LIST;
    this.nominationUniqueId = NominationUtility.randomFixedIntegerString(3) + NominationUtility.getUnixTimeStamp();
    window.scroll(0, 0);
    this.nominationService.getAllBUList().subscribe((result) => {
      this.buNameList = result;
    });
    this.awardEntryForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._-]+@dmi[a-z]+.com')]],
      location: [null, Validators.required],
      bu: [null, Validators.required],
      citation: ['', Validators.required],
      docFile: [null],
      photo : [''],
      audioOrvideo: ['',]
    }); 
    this.resetDocumetsField();
  }

  


  ngAfterViewInit(): void {
    this.nominationService.setSubNavMenuStatus(true);
    this.subScribeUrl = this.route
      .queryParams
      .subscribe(params => {
        this.awardNameDisplay = params['award'];
        console.log(params['award']);
      });
  }

  get f() { return this.awardEntryForm.controls; }

  resetDocumetsField() {
    this.docFileUpload = NominationConstants.DOC_FILE_UPLOAD_PLACEHOLDER;
    this.audioOrVideoFileUpload = NominationConstants.AUDIO_VIDEO_FILE_UPLOAD_PLACEHOLDER;
    this.photoFileUpload = NominationConstants.PHOTO_FILE_UPLOAD_PLACEHOLDER;
    this.videoFileUpload = NominationConstants.VIDEO_FILE_UPLOAD_PLACEHOLDER;
  }

  callBackMessageHandler(type, message) {
    if (type == "error") {
      this.showSuccess = false;
      this.showError = true;
      this.errorMsg = message;
      this.successMsg = "";
    } else {
      this.showSuccess = true;
      this.showError = false;
      this.errorMsg = "";
      this.successMsg = message;
    }
  }


  onDocFileChange(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileExt = NominationUtility.getFileExtension(file.name);
      if (!NominationUtility.checkFileSize(file.size, this.DOC_FILE_SIZE_LIMIT)) {
        const msg = NominationUtility.fileSizeErrorMsg(this.DOC_FILE_SIZE_LIMIT);
        this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
        this.docFileInput.nativeElement.value = null;
        this.hideMessageDisplay();
        return;
      }
      else if (!this.docAllowType.includes(fileExt)) {
        const msg = NominationUtility.fileTypeErrorMsg(
          (NominationConstants.DOC_FILE_ALLOW_TYPES).join());
        this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
        this.docFileInput.nativeElement.value = null;
        this.hideMessageDisplay();
        return;
      }
      this.docFileUpload = file.name;
      this.uploadDocFile();
    }
  }

  onPhotoFileChange(event){
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileExt = NominationUtility.getFileExtension(file.name);
      if (!NominationUtility.checkFileSize(file.size, this.PHOTO_FILE_SIZE_LIMIT)) {
        const msg = NominationUtility.fileSizeErrorMsg(this.PHOTO_FILE_SIZE_LIMIT);
        this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
        this.photoFileInput.nativeElement.value = null;
        this.hideMessageDisplay();
        return;
      }
      else if (!this.photoAllowType.includes(fileExt)) {
        const msg = NominationUtility.fileTypeErrorMsg(
          (NominationConstants.AUDIO_FILE_ALLOW_TYPES).join());
        this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
        this.photoFileInput.nativeElement.value = null;
        this.hideMessageDisplay();
        return;
      }
      this.photoFileUpload = file.name;
      this.uploadPhotoFile();
    }
  }

  onAudioVideoFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileExt = NominationUtility.getFileExtension(file.name);
      if (!this.audioAllowType.includes(fileExt)) {
        const msg = NominationUtility.fileTypeErrorMsg(
          (NominationConstants.AUDIO_FILE_ALLOW_TYPES).join());
        this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
        this.audioOrVideoFileInput.nativeElement.value = null;
        this.hideMessageDisplay();
        return;
      }
      let media;
      const audioFormat = ['m4a','mp3','wav', 'ogg'];
      const videoFormat = ['mov','mp4','wmv','flv','avi'];
      if(audioFormat.includes(fileExt)){
        media = document.createElement('audio');
        media.preload = 'metadata';
        media.onloadedmetadata = ()=> {
          window.URL.revokeObjectURL(media.src);
          this.duration = media.duration; // here you could get the duration
          // if(Math.floor(this.duration/60)<1){
          //   this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, this.mediaDurationMsg);
          //   this.audioOrVideoFileInput.nativeElement.value = null;
          //   // this.awardEntryForm.value['audio_file_name']='';
          //   this.awardEntryForm.controls['audio_file_name'].reset()
          //   this.hideMessageDisplay();
          //   return;
          // }
          // if (!NominationUtility.checkFileSize(file.size, this.AUDIO_FILE_SIZE_LIMIT)) {
          //   const msg = NominationUtility.fileSizeErrorMsg(this.AUDIO_FILE_SIZE_LIMIT);
          //   this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
          //   this.audioOrVideoFileInput.nativeElement.value = null;
          //   this.hideMessageDisplay();
          //   return;
          // }
        }
        media.src = URL.createObjectURL(file);
      }
      else if(videoFormat.includes(fileExt)){
        media = document.createElement('video');
        media.preload = 'metadata';
        media.onloadedmetadata = ()=> {
          window.URL.revokeObjectURL(media.src);
          this.duration = media.duration; // here you could get the duration
          // if(Math.floor(this.duration/60)<1){
          //   this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, this.mediaDurationMsg);
          //   this.audioOrVideoFileInput.nativeElement.value = null;
          //   // this.awardEntryForm.value['audio_file_name']='';
          //   this.awardEntryForm.controls['audio_file_name'].reset()
          //   this.hideMessageDisplay();
          //   return;
          // }
          // if (!NominationUtility.checkFileSize(file.size, this.VIDEO_FILE_SIZE_LIMIT)) {
          //   const msg = NominationUtility.fileSizeErrorMsg(this.VIDEO_FILE_SIZE_LIMIT);
          //   this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
          //   this.audioOrVideoFileInput.nativeElement.value = null;
          //   this.hideMessageDisplay();
          //   return;
          // }
        }
        media.src = URL.createObjectURL(file);
      }
      // if (!NominationUtility.checkFileSize(file.size, this.AUDIO_FILE_SIZE_LIMIT)) {
      //   const msg = NominationUtility.fileSizeErrorMsg(this.AUDIO_FILE_SIZE_LIMIT);
      //   this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
      //   this.audioOrVideoFileInput.nativeElement.value = null;
      //   this.hideMessageDisplay();
      //   return;
      // }
      // else if (!this.audioAllowType.includes(fileExt)) {
      //   const msg = NominationUtility.fileTypeErrorMsg(
      //     (NominationConstants.AUDIO_FILE_ALLOW_TYPES).join());
      //   this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, msg);
      //   this.audioOrVideoFileInput.nativeElement.value = null;
      //   this.hideMessageDisplay();
      //   return;
      // }
      this.audioOrVideoFileUpload = file.name;
      this.uploadAudioFile();
    }
  }

  appendDataToFile(file, uploadedFile) {
    file.append(NominationConstants.FILE_UNIQUE_ID, this.nominationUniqueId);
    file.append(NominationConstants.DOC_FILE, uploadedFile);
    return file;
  }

  uploadDocFile() {
    const uploadedFile = this.docFileInput.nativeElement.files[0];
    const file = new FormData();
    const newFile = this.appendDataToFile(file, uploadedFile);
    const uri = `${this.baseUri}${this.fileUpload}`;
    this.uploadFile(uri, newFile);
  }

  uploadPhotoFile(){
    const uploadedAudio = this.photoFileInput.nativeElement.files[0];
    const file = new FormData();
    const newFile = this.appendDataToFile(file, uploadedAudio);
    const uri = `${this.baseUri}${this.photoUpload}`;
    this.uploadFile(uri, newFile);
  }

  uploadAudioFile() {
    const uploadedAudio = this.audioOrVideoFileInput.nativeElement.files[0];
    const file = new FormData();
    const newFile = this.appendDataToFile(file, uploadedAudio);
    const uri = `${this.baseUri}${this.audioUpload}`;
    this.uploadFile(uri, newFile);
  }

  uploadFile(uri, fileUploaded) {
    this.http.post(uri, fileUploaded).subscribe(res => {
      console.log("file uplaoded to server", res);
      this.hideMessageDisplay();
    }, error => {
      this.callBackMessageHandler('error', `Error while adding file.`);
      this.hideMessageDisplay();
      console.log("error", error);
    },
      () => {
        console.log("Upadted record");
      });
  }

  getUserData() {
    const audioOrVideoFile = this.audioOrVideoFileInput.nativeElement.files[0];
    const docFileName = this.docFileInput.nativeElement.files[0];
    const photoFile = this.photoFileInput.nativeElement.files[0];

    const newPhotoFileName: string | null = !!photoFile ?
    `photo_${this.nominationUniqueId}${NominationUtility.removeAllWhiteSpaces(photoFile.name)}` : null;
    const newDocFileName: string | null = !!docFileName ?
      `doc_${this.nominationUniqueId}${NominationUtility.removeAllWhiteSpaces(docFileName.name)}` : null;
    const newAudioOrVideoFile: string | null = !!audioOrVideoFile ?
      `audio_${this.nominationUniqueId}${NominationUtility.removeAllWhiteSpaces(audioOrVideoFile.name)}` : null;

    this.loggedInUserId = this.nominationService.getValueToLocalStorage(NominationConstants.ID);
    this.loggedInUserEmail = this.nominationService.getValueToLocalStorage('email');
    const awarList = this.nominationService.getValueToLocalStorage(NominationConstants.AWARD_LIST);
    const awardId = NominationUtility.getAwardID(awarList, this.awardNameDisplay);
    this.awardEntryForm.value[NominationConstants.USER_ID] = this.loggedInUserId;
    this.awardEntryForm.value['user_email_id'] = this.loggedInUserEmail;
    this.awardEntryForm.value['emp_nomination_client_id'] = this.nominationUniqueId;
    this.awardEntryForm.value['docFile'] = newDocFileName;
    this.awardEntryForm.value['photoFile'] = newPhotoFileName;
    this.awardEntryForm.value['audio_file_name'] = newAudioOrVideoFile;
    this.awardEntryForm.value[NominationConstants.AWARD_ID] = awardId;
  }

  private hideMessageDisplay() {
    this.timeOut = window.setTimeout(() => {
      this.showSuccess = false;
      this.showError = false;
    }, this.timerDelay);
  }

  public onSubmit() {
    this.submitted = true;
    const audioVideoFile = this.audioOrVideoFileInput.nativeElement.files[0];
    const photoFile = this.photoFileInput.nativeElement.files[0];

    if (this.awardEntryForm.invalid) {
      return;
    }
    // else if (!audioVideoFile && !photoFile) {
    //   this.callBackMessageHandler('error', `Please upload supporting file (Audio or Video or Photo).`);
    //   this.hideMessageDisplay();
    //   return;
    // }
    // else if(Math.floor(this.duration/60)<1){
    //   this.callBackMessageHandler(NominationConstants.ERROR_MESSAGE, this.mediaDurationMsg);
    //   this.hideMessageDisplay();
    //   return;
    // }
    this.getUserData();
    const employeeFormEntry = NominationFormDataParser.Employee(this.awardEntryForm.value);
    this.nominationService.addEmployeeNomination(employeeFormEntry).subscribe((result) => {
      this.callBackMessageHandler(NominationConstants.SUCCESS_MESSAGE,
        NominationConstants.messages.SUCCESS.NOMINATION_FORM_DATA);
        this.toogleSubmit = true;
        this.hideMessageDisplay();
        setTimeout(()=>{
          this.clearForm();
          this.router.navigate(['/home/employee'])
        },2000)
       
    }, error => {
      this.toogleSubmit = false;
      this.callBackMessageHandler('error', `Error while processing data.`);
      this.hideMessageDisplay();
      console.log("error", error);
    },
      () => {
        console.log("Upadted record");
      });
  }

  clearForm() {
    this.toogleSubmit = false;
    this.submitted = false;
    this.awardEntryForm.reset();
    this.resetDocumetsField();
    this.nominationUniqueId = NominationUtility.randomFixedIntegerString(3) + NominationUtility.getUnixTimeStamp();
  }

  ngOnDestroy() {
    window.clearTimeout(this.timeOut);
    !!this.subScribeUrl && this.subScribeUrl.unsubscribe();
  }
}