import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NominationService } from '../shared/services/nomination.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public submitted: boolean = false;
  public subscriptions: Subscription;
  public showSuccess: boolean;
  public showError: boolean;
  public errorMsg: string = "";
  public successMsg: string = "";
  public timeOut;
  public timerDelay: number = 3000;
  public toogleSubmit: boolean = false;

  constructor(private router: Router, public formBuilder: FormBuilder,
    private nominationService: NominationService) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group(
      {
        useremail: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._-]+@dmi[a-z]+.com')]],
        password: ['', Validators.required],
        confirmedPassword: ['', Validators.required],
      },
      {
        validator: this.ConfirmPasswordValidator("password", "confirmedPassword")
      }
    );
  }

  ConfirmPasswordValidator(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else if (!passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ required: true });
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  callBackMessageHandler(type, message) {
    if (type == "error") {
      this.showSuccess = false;
      this.showError = true;
      this.errorMsg = message;
      this.successMsg = "";
    } else {
      this.showSuccess = true;
      this.showError = false;
      this.errorMsg = "";
      this.successMsg = message;
    }
  }

  get f() { return this.resetPasswordForm.controls; }

  getRestPasswordLink() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.toogleSubmit = true;
    const userLoginDetails = this.resetPasswordForm.value;
    this.subscriptions = this.nominationService.resetUserPassword(userLoginDetails).subscribe(result => {
      this.callBackMessageHandler("success", `Your password has been changed successfully! Please login.`);
      this.timeOut = window.setTimeout(() => {
        this.showSuccess = false;
        this.showError = false;
        this.toogleSubmit = false;
        this.submitted = false;
        this.resetPasswordForm.reset();
      }, this.timerDelay);
    },
      error => {
        this.callBackMessageHandler("error", "User not found!");
        this.timeOut = window.setTimeout(() => {
          this.showSuccess = false;
          this.showError = false;
          this.toogleSubmit = false;
        }, this.timerDelay);
        console.log("error", error);
      },
      () => {
        console.log("Password updated");
      }
    );
  }
}
