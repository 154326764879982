<nav class="navbar navbar-inverse dmi-home-nav">
    <div class="container-fluid">
    </div>
</nav>
<div class="login-form">
    <div class="message-wrapper">
        <div class="alert alert-success animate__animated animate__bounceIn" *ngIf="showSuccess">
            {{successMsg}}
        </div>
        <div class="alert alert-danger animate__animated animate__bounceIn" *ngIf="showError">
            {{errorMsg}}
        </div>
    </div>
    <form [formGroup]="employeeRegistration">
        <div class="avatar">
            <img src="../../assets/img/dmi-login-header-1.png" alt="dmi-logo" srcset="" width=220px
             height=100px>
        </div>
        <h4 class="modal-title">Register with DMI Email</h4>
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <input type="text" formControlName="firstName" placeholder="First Name" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="col">
                    <input type="text" formControlName="lastName" placeholder="Last Name" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" formControlName="email" placeholder="Email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                <div *ngIf="f.email.errors.pattern">Email address must be a valid DMI email Id</div>
            </div>
        </div>
        <div class="form-group">
            <input type="password" formControlName="password" placeholder="Password" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1 col-sm-1 col-1">
                <input type="checkbox" #termsSelection name="terms" id="terms" class="form-control term-chk-box"
                    (change)="changeEvent($event)">
            </div>
            <div class="col-md-10 col-sm-10 col-10">
                <p class="term-body">
                    DMI Annual Award Platform respects your privacy and is committed to protecting it through our
                    compliance policy. This privacy policy applies to each DMI website, product, and other service
                    (each, a “Service”) that refers or links
                    to this policy. Any such dissemination, distribution or copying of this data is strictly prohibited.
                </p>
            </div>
        </div>

        <input type="button" class="btn btn-primary btn-block btn-lg" (click)="doSignup()" value="Register"
            [disabled]="toogleSubmit"><br>
            <div class="text-center small sign-in-link"><a routerLink="/login" class="btn btn-link">Sign in  instead</a></div>
    </form>
</div>