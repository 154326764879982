<nav class="navbar navbar-inverse dmi-home-nav">
    <div class="container-fluid">
        <div class="navbar-header">
            <span class="navbar-brand">
            </span>
        </div>
        <ul class="nav navbar-nav navbar-right sign-out">
            <li></li>
        </ul>
    </div>
</nav>

<div class="login-form form-responsive">
    <div class="message-wrapper">
        <h4 class="modal-title">Nominations has been closed for 2023, </h4>
        <h4 class="modal-title">Thank you for nominating!!</h4>
        
    </div>
</div>