import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NominationService } from '../shared/services/nomination.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public nominationService: NominationService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.nominationService.getValueToLocalStorage("auth-token")) {
      // Redirecting when nominations has been closed
      if(this.nominationService.getValueToLocalStorage("user_role") === 'employee'){

        if(this.checkForNominationAllowed()){  //if true that means it is year starting
          this.router.navigate(['/nominations-closed']);
        }
      }
      return true;
    } else {
      this.router.navigate(['/login']);
    }

  }

  /**
   * @description This function will return false if we are in the end of the month like november/december
   *              Before this it will return true
   * @returns boolean
   */
  checkForNominationAllowed() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    if(currentMonth > 10) return false; //10 is month number
    return true;
  }

}

