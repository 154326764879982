<div *ngIf="isNominationAllowed" class="card-wrapper">
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="card">
                <div class="card-body">
                    <div class="">
                        <div class="">
                            <img src="../../../assets/img/hi-five-1.png"  alt="Hi-five"
                                class="img-fluid"/>
                        </div>
                        <div class="col">
                            <div class="card-block">
                                <!-- <h1 class="card-title">Hi-Five</h1> -->
                                <p class="card-text">This award is given to a team that has made a 
                                    significant impact at DMI. This award recognizes 
                                    a team or project that has been recognized by the 
                                    customer and DMI leadership as having delivered 
                                    measurable results that testify to the project 
                                    team’s exceptional level of dedication, delivery 
                                    excellence, and/or innovation.
                                </p>
                                <button type="button" class="btn btn-primary btn-success" 
                                    (click)="nominateTeam('HI-FIVE')" *ngIf="isNominationAllowed"
                                >Submit Nomination</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        </div>
    </div>
</div>