import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NominationService } from '../shared/services/nomination.service';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadAuthGuard implements CanLoad {
  constructor(public nominationService: NominationService, private router: Router) { }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.nominationService.getValueToLocalStorage("auth-token") && this.nominationService.getValueToLocalStorage("user_role") === 'admin-superAdmin') {
      return true;
    } else if (this.nominationService.getValueToLocalStorage("auth-token") && this.nominationService.getValueToLocalStorage("user_role") === 'admin') {
      return true;
    } else {
      this.nominationService.removeUserInfo();
      this.router.navigate(['/home/logout']);
    }
  }
}
