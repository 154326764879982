<div class="col-lg-6 col-md-6 col-sm-12 offset-md-3 award-entry-form">
    <div class="row">
        <div class="message-wrapper">
            <div class="alert alert-success animate__animated animate__bounceIn" *ngIf="showSuccess">
                {{successMsg}}
            </div>
            <div class="alert alert-danger animate__animated animate__bounceIn" *ngIf="showError">
                {{errorMsg}}
            </div>
        </div>
        <div class="entry-form-title">
            <h2>Nomination for <b>{{awardNameDisplay}}</b></h2>
        </div>
    </div>
    <hr>
    <div class="">
        <div class="">
            <form [formGroup]="awardEntryForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label>Team Name<span class="require-field">*</span></label>
                    <input type="text" formControlName="teamName" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.teamName.errors }" />
                    <div *ngIf="submitted && f.teamName.errors" class="invalid-feedback">
                        <div *ngIf="f.teamName.errors.required">Team Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Employee Names* (enter first name, last name of each member in a team)<span class="require-field">*</span></label>
                    <input type="text" formControlName="empName" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.empName.errors }" />
                    <div *ngIf="submitted && f.empName.errors" class="invalid-feedback">
                        <div *ngIf="f.empName.errors.required">Please enter at least one
                            emploeey name</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Citation (brief description)<span class="require-field">*</span></label>
                    <textarea rows="4" cols="50" maxlength="2000" formControlName="citation" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.citation.errors }"></textarea>
                    <div *ngIf="submitted && f.citation.errors" class="invalid-feedback">
                        <div *ngIf="f.citation.errors.required">Citation is required</div>
                    </div>
                </div>
                <fieldset class="border p-2">
                    <legend class="doc-fieldset-title">Supporting file(s):
                    </legend>
                    <div class="form-group">
                        <label>Nominee Photo (Team Collage Picture)</label>
                    <div class="input-group">
                        <div class="custom-file upload-audio-video">
                            <input type="file" #photoFileInput formControlName="photo"
                                class="custom-file-input form-control" id="photo-file" aria-describedby="inputGroupFileAddon01"
                                (change)="onPhotoFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f.photo.errors }">
                            <label class="custom-file-label" for="audio-file">{{photoFileUpload}}</label>
                            <div *ngIf="submitted && f.photo.errors" class="invalid-feedback">
                                <div *ngIf="f.photo.errors.required">Nominee Photo (Team Collage Picture) is required</div>
                            </div>
                        </div>
                        
                    </div>
                    <span>
                        <i class="fa fa-info-circle info-icon" aria-hidden="true"></i>
                        <i class = "photo-text">Upload it or alternately email it to hr@dminc.com by December 31, 2023</i>
                    </span>
                    <br/>
                    <span>Supporting file formats: 
                        <i>"jpeg", "jpg", "png"</i>
                    </span>
                    <br/>
                    <span>Maximum File Size:
                        <i>10MB</i>
                    </span>
                </div>
                <div class="form-group">
                        <label>Audio/Video <span class="help-field"> (preferred but not essential)</span></label>
                        <div class="input-group">
                            <div class="custom-file upload-audio-video">
                                <input type="file" #audioOrVideoFileInput formControlName="audioOrvideo"
                                    class="custom-file-input form-control" id="audio-video-file" aria-describedby="inputGroupFileAddon01"
                                    (change)="onAudioVideoFileChange($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.audioOrvideo.errors }">
                                <label class="custom-file-label" for="audio-file">{{audioOrVideoFileUpload}}</label>
                                <!-- <div *ngIf="submitted && f.audioOrvideo.errors" class="invalid-feedback">
                                    <div *ngIf="f.audioOrvideo.errors.required">Audio or Video is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <span>
                            <i class="fa fa-info-circle info-icon" aria-hidden="true"></i>
                            <i class = "photo-text">Audio or video should be atleast of 60 seconds</i>
                        </span> -->
                        <!-- <br/> -->

                        <span>Supporting file formats:
                            <i>"m4a", "ffac", "mp3", "mp4", "wav", "mov", "wmv", "flv", "avi", "ogg"</i>
                        </span>
                        <!-- <br/> -->
                        <!-- <span>Maximum file size:
                            <i>
                                Video - 30MB, Audio - 15MB
                            </i>
                        </span> -->
                    </div>
                    <div class="form-group">
                        <label>Document</label>
                        <div class="input-group">
                            <div class="custom-file">
                                <input type="file" formControlName="docFile" #docFileInput name="docFile"
                                    class="custom-file-input" id="doc-file" aria-describedby="inputGroupFileAddon02"
                                    (change)="onDocFileChange($event)">
                                <label class="custom-file-label" for="doc-file">{{docFileUpload}}</label><br>
                            </div>
                            <!-- <button type="button" class="btn btn-success" (click)="uploadDocFile()" [disabled]="isFileUploadDisabled">Upload</button> -->
                        </div><br>
                        <span>Supporting file formats: 
                            <i>"pdf", "docx", "doc", "ppt", "pptx"</i>
                        </span>
                        <br/>
                        <span>Maximum File Size:
                            <i>10MB</i>
                        </span>
                    </div>
                </fieldset><br>
                <div class="row ml-1">
                    <div class="mr-2">
                        <button class="btn btn-primary" type="submit"
                            [disabled]="toogleSubmit">Submit</button>
                    </div>
                    <div>
                        <!-- <button class="btn btn-primary" type="button" (click)="clearForm()">Add
                            new Nomination</button> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>