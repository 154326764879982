import { Component, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { NominationService } from '../shared/services/nomination.service';
import { NominationUtility } from '../shared/utilities/nomination-utility';

import { Router } from '@angular/router';
import { NominationConstants } from '../shared/constants/nomination.constant';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentChecked {
  public canShowLinkMenu: boolean = false;
  public userFullName: string;
  public swithcToSuperUserView: boolean = false;
  public swithcToAdminView: boolean = false;
  public swithcToEmpView: boolean = false;
  public allEmpNominations : any;
  public isNominationAllowed: boolean = false;

  constructor(private nominationService: NominationService, 
    private router: Router, 
    private cRef: ChangeDetectorRef,
    private authService: MsalService,
    ) {

  }

  buttonViewHandler(viewType) {
    if (viewType == "adminView") {
      this.swithcToSuperUserView = false;
      this.swithcToAdminView = true;
      this.swithcToEmpView = false;
    } else if (viewType == "juryView") {
      this.swithcToSuperUserView = true;
      this.swithcToAdminView = false;
      this.swithcToEmpView = false;
    } else if (viewType == "employeeView") {
      this.swithcToSuperUserView = false;
      this.swithcToAdminView = false;
      this.swithcToEmpView = true;
    } else if (viewType == "adminSuperAdminView") {
      this.swithcToSuperUserView = true;
      this.swithcToAdminView = true;
      this.swithcToEmpView = false;
    }

  }
  ngOnInit(): void {
    window.scroll(0, 0);
    this.userFullName = this.nominationService.getValueToLocalStorage("full_name");
    let userRole = this.nominationService.getValueToLocalStorage("user_role");
    let userLoginRole = this.nominationService.getValueToLocalStorage("last_login_as");
    const viewButton = NominationUtility.getSwitchViewButton(userRole, userLoginRole);
    this.isNominationAllowed = NominationConstants.IS_NOMINATION_ALLOWED;
    this.buttonViewHandler(viewButton);
    console.log("this.userFullName", this.userFullName);
  }


  logout() {
    this.nominationService.removeUserInfo();
    this.authService.logout();
    this.router.navigate(["home/logout"]);
  }

  queryParamForSwitchView(changeViewTo) {
    let changeLastLoginTo = '';
    const loginUserId = this.nominationService.getValueToLocalStorage("id");
    if (changeViewTo === 'juryView') {
      changeLastLoginTo = 'super-user';
    } else if (changeViewTo === 'adminView') {
      changeLastLoginTo = 'admin';
    } else if (changeViewTo === 'empView') {
      changeLastLoginTo = 'employee';
    }
    return { 'loginUserId': loginUserId, 'login_role': changeLastLoginTo };
  }

  switchView(switchViewTo) {
    const getViewData = this.queryParamForSwitchView(switchViewTo);
    this.nominationService.changeLoginUserRole(getViewData).subscribe((data) => {
      this.nominationService.setValueToLocalStorage("last_login_as", getViewData['login_role']);
      this.canShowLinkMenu = data
      if (getViewData['login_role'] === 'admin') {
        this.nominationService.setSelectedYear("Current Year");
        this.router.navigate(['/admins']);
      } else {
        this.router.navigate(['/jury-panel']);
      }
    },
      error => {
        console.log("error", error);
      },
      () => {
        console.log("logged in");
      }
    );
  }

 

  
  ngAfterContentChecked(): void {
    this.cRef.detectChanges();
    this.nominationService.getSubNavMenuStatus().subscribe((data) => {
      this.canShowLinkMenu = data
    });
  }
}
