import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NominationService } from '../shared/services/nomination.service';
import { Subscription } from 'rxjs';
import { AuthenticationParameters } from "msal";
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public showSuccess: boolean = false;
  public showError: boolean = false;
  public successMsg: string = "";
  public errorMsg: string = "";
  public employeeLogin: FormGroup;
  public submitted: boolean = false;
  private timeOut: number;
  private timerDelay: number = 2000;
  subscriptions: Subscription[] = [];
  isIframe = false;
  loggedIn = false;

  constructor(private router: Router, public formBuilder: FormBuilder,
    private nominationService: NominationService,private broadcastService: BroadcastService, private authService: MsalService) { }

  ngOnInit(): void {
    // TODO nr.4: call Azure authentication function in ngOnInit
    this.azureAuthOnInit();
    this.employeeLogin = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  azureAuthOnInit() {
    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;

    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', (resultPayload:any) => {
      this.nominationService.setUser({name:resultPayload.idTokenClaims.name, email:resultPayload.idTokenClaims.preferred_username})
      .subscribe(result => {
          this.nominationService.setValueToLocalStorage("full_name", result['full_name']);
      this.nominationService.setValueToLocalStorage("last_login_as", result['last_login_as']);
      this.nominationService.setValueToLocalStorage("user_role", result['user_role']);
      this.nominationService.setValueToLocalStorage("auth-token", result['auth-token']);
      this.nominationService.setValueToLocalStorage("id", result['id']);
      this.nominationService.setValueToLocalStorage("email", result['email']);
          let userLoginRole = result["last_login_as"];
      if (userLoginRole === 'admin') {
      this.router.navigate(['/admins']);
      } else if (userLoginRole === 'super-user') {
        this.router.navigate(['/jury-panel']);
      } else {
        this.router.navigate(['/home']);
      }
        console.log("USER SET UP PAYLOAD",result)
      })
      this.checkAccount();
    });

    loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
      console.log('Login Fails:', error);
    });

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

    //  console.log('Redirect Success: ', response.accessToken);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
  }

  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  get f() { return this.employeeLogin.controls; }

  callBackMessageHandler(type, message) {
    if (type == "error") {
      this.showSuccess = false;
      this.showError = true;
      this.errorMsg = message;
      this.successMsg = "";
    } else {
      this.showSuccess = true;
      this.showError = false;
      this.errorMsg = "";
      this.successMsg = message;
    }
  }

  public doLogin() {
    const account:any={
      userName:this.employeeLogin.value.email
    }
    const reqParam : AuthenticationParameters = {
      account:account
    }
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (isIE) {
      this.authService.loginPopup(reqParam);
    } else {
      this.authService.loginPopup(reqParam);
    }
    // this.submitted = true;
    // if (this.employeeLogin.invalid) {
    //   return;
    // }
    // const userLoginDetails = {
    //   email:this.employeeLogin.value.email,
    //   password:this.employeeLogin.value.password
    // }
    // this.nominationService.validateUserLogin(userLoginDetails).subscribe(result => {
    //   // Handle result
    //   // console.log(result);
    //   this.nominationService.setValueToLocalStorage("full_name", result['full_name']);
    //   this.nominationService.setValueToLocalStorage("last_login_as", result['last_login_as']);
    //   this.nominationService.setValueToLocalStorage("user_role", result['user_role']);
    //   this.nominationService.setValueToLocalStorage("auth-token", result['auth-token']);
    //   this.nominationService.setValueToLocalStorage("id", result['id']);
    //   this.nominationService.setValueToLocalStorage("email", result['email']);
    //   let userLoginRole = this.nominationService.getValueToLocalStorage("last_login_as");
    //   if (userLoginRole === 'admin') {
    //   this.router.navigate(['/admins']);
    //   } else if (userLoginRole === 'super-user') {
    //     this.router.navigate(['/jury-panel']);
    //   } else {
    //     this.router.navigate(['/home']);
    //   }
    // },
    //   error => {
    //     this.callBackMessageHandler("error", "Error! Username or password is incorrect.");
    //     this.timeOut = window.setTimeout(() => {
    //       this.showSuccess = false;
    //       this.showError = false;
    //     }, this.timerDelay);
    //     console.log("error", error);
    //   },
    //   () => {
    //     console.log("logged in");
    //   }
    // );
  }

  ngOnDestroy(): void {
    // TODO nr.6: Unsubscribe when component is destroyed. (Performance)
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
