import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { NominationService } from '../../shared/services/nomination.service';
import { NominationConstants } from '../../shared/constants/nomination.constant';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, AfterContentInit {
  public isNominationAllowed: boolean = false;
  constructor(private router: Router, private nominationService: NominationService) { }

  ngOnInit(): void {
    this.isNominationAllowed = NominationConstants.IS_NOMINATION_ALLOWED;
    window.scroll(0, 0);
  }

  public nominateTeam(awardName) {
    this.router.navigate(['/home/team-entries'], { queryParams: { award: awardName } });
    this.nominationService.setSubNavMenuStatus(true);
  }

  ngAfterContentInit(): void {
    this.nominationService.setSubNavMenuStatus(false);
  }
}
