<nav class="navbar navbar-inverse dmi-home-nav">
    <div class="container-fluid">
        <div class="navbar-header">
            <span class="navbar-brand">
            </span>
        </div>
        <ul class="nav navbar-nav navbar-right sign-out">
            <li><a routerLink="/home/logout" (click)="logout()"><span class="fa fa-sign-out" title="Logout"></span></a></li>
        </ul>
    </div>
</nav>
<div class="row sub-menu-link">
    <div class="col-lg-3 col-md-3 col-sm-1 col-1"></div>
    <div class="col-lg-6 col-md-6 col-sm-10 col-10">
    </div>
    <div class="col-lg-3 col-md-3 col-sm-1 col-1">
        <div class="row">
            <div class="col view-change">
                <button *ngIf="swithcToSuperUserView" class="btn btn-primary float-right view-change-btn" (click)="switchView('juryView')">
                    Switch to jury view</button>
                <button *ngIf="swithcToAdminView" class="btn btn-primary float-right view-change-btn margin" (click)="switchView('adminView')">
                    Switch to admin view</button>
                <button *ngIf="swithcToEmpView" class="btn btn-primary float-right view-change-btn" (click)="switchView('empView')">
                    Switch to employee view</button>
                    
            </div>
        </div>
    </div>
</div>
<div *ngIf="isNominationAllowed" class="navbar navbar-expand-sm navbar-dark justify-content-center">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a routerLink="/home/employee" class="nav-link" routerLinkActive="active">Individual Awards</a>
        </li>
        <li class="nav-item">
            <a routerLink="/home/team" class="nav-link" [routerLinkActive]="['active']">Team Awards</a>
        </li>
    </ul>
</div>
<div *ngIf="isNominationAllowed" class="row sub-menu-link">
    <div class="col-lg-3 col-md-3 col-sm-1 col-1"></div>
    <div class="col-lg-6 col-md-6 col-sm-10 col-10">
        <ul class="nav nav-pills sub-menu-link-mydmi" [hidden]="canShowLinkMenu">
            <li class="nav-item" >
                For detailed information and guidelines please visit<a href="https://dgtmgt.sharepoint.com/sites/HR/SitePages/Global-Annual-Awards-2023.aspx?from=SendByEmail&e=i38jiH2xIU-E1GG3jCuqfQ&at=9" class="nav-link mydmin-detail" target="_blank">MYDMI</a>
            </li>
        </ul>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-1 col-1"></div>
</div>
<div *ngIf="!isNominationAllowed" class="row justify-content-center" style="margin-top: 200px; font-size: x-large; color: antiquewhite;">    
    <p><b>Nominations are now closed for the 2021 DMI Annual Awards - watch this space!</b></p>
</div>
<router-outlet></router-outlet>