import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { NominationService } from '../../shared/services/nomination.service';
import { NominationConstants } from '../../shared/constants/nomination.constant';
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit, AfterContentInit {
  public isNominationAllowed: boolean = false;
  
    constructor(private router: Router, private nominationService: NominationService) { }

ngOnInit(): void {
  this.isNominationAllowed = NominationConstants.IS_NOMINATION_ALLOWED;
  window.scroll(0, 0);
  this.nominationService.setSubNavMenuStatus(false);
  this.nominationService.getAllAwardList().subscribe(result => {
    this.nominationService.setValueToLocalStorage("award_list", JSON.stringify(result));
  });
}
  public nominateEmployee(awardName) {
  this.router.navigate(['/home/entries'], { queryParams: { award: awardName } });
  this.nominationService.setSubNavMenuStatus(true);
}
ngAfterContentInit(): void {
  this.nominationService.setSubNavMenuStatus(false);
}

}