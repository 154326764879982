import { Component, OnInit, OnDestroy,ViewChildren,ElementRef,QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NominationService } from '../shared/services/nomination.service';
import { Subscription } from 'rxjs';
import { IuserSignUp } from '../shared/interfaces/nomination.interface';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  public showSuccess: boolean = false;
  public showError: boolean = false;
  public successMsg: string = "";
  public errorMsg: string = "";
  public employeeRegistration: FormGroup;
  public submitted: boolean = false;
  public toogleSubmit: boolean = true;
  private timeOut: number;
  private timerDelay: number = 2000;
  
  private subscriptions = new Subscription();

  @ViewChildren("termsSelection") termsSelection: QueryList<ElementRef>;

  constructor(private router: Router, public formBuilder: FormBuilder,
    private nominationService: NominationService) { }

  ngOnInit(): void {
    this.employeeRegistration = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._-]+@dmi[a-z]+.com')]],
      password: ['', Validators.required]
    });
  }

  callBackMessageHandler(type, message) {
    if (type == "error") {
      this.showSuccess = false;
      this.showError = true;
      this.errorMsg = message;
      this.successMsg = "";
    } else {
      this.showSuccess = true;
      this.showError = false;
      this.errorMsg = "";
      this.successMsg = message;
    }
  }

  get f() { return this.employeeRegistration.controls; }

  public doSignup() {
    this.submitted = true;
    if (this.employeeRegistration.invalid) {
      return;
    }
    const userLoginDetails: IuserSignUp = this.employeeRegistration.value;
    this.toogleSubmit = true;
    this.subscriptions = this.nominationService.addnewUser(userLoginDetails).subscribe(result => {
      this.callBackMessageHandler("success", `Activation email has been sent to your registered e-mail id.`);
      this.timeOut = window.setTimeout(() => {
        this.showSuccess = false;
        this.showError = false;
        this.submitted = false;
        this.employeeRegistration.reset();
        this.termsSelection.forEach((element) => {
          element.nativeElement.checked = false;
        });
        this.toogleSubmit = true;
      }, this.timerDelay);
    },
      error => {
        this.callBackMessageHandler("error", "Error! While creating user.!");
        this.timeOut = window.setTimeout(() => {
          this.showSuccess = false;
          this.showError = false;
          this.toogleSubmit = false;
        }, this.timerDelay);
        console.log("error", error);
      },
      () => {
        console.log("logged in");
      }
    );
  }

  changeEvent(ev) {
    this.toogleSubmit = !ev.target.checked;
  }

  ngOnDestroy() {
    window.clearTimeout(this.timeOut);
    this.subscriptions.unsubscribe();
  }
}
