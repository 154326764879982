import {NominationConstants} from '../constants/nomination.constant';

export class NominationUtility {

    static trimAllSpaces(str: string): string {
        return str.replace(/\s+/g, '-').toLowerCase();
    }
    static removeAllWhiteSpaces(str: string): string {
        return str.replace(/\s+/g, '').toLowerCase();
    }
    static checkFileSize(fileSize, fileSiz = 1): boolean {
        const fileSizeInMb: number = +(fileSize / (1024 * 1024)).toFixed(2);
        return fileSizeInMb <= fileSiz;
    }
    static getFileExtension(fileName): string {
        return (fileName.split('.').pop()).toLowerCase();;
    }

    static randomFixedIntegerString(length): string {
        return "" + Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
    }
    static getUnixTimeStamp(): string {
        return "" + Math.round(+new Date() / 1000);
    }
    
    static createReadableMessage(str, replaceWithFirst = '', replaceWithSecond = '', replaceWithThird = ''): string {
        return str.replace('xxxx',replaceWithFirst).replace('yyyy',replaceWithSecond).replace('zzzz',replaceWithThird);
    }

    static getSwitchViewButton(userRole, LastLoginRole) {
        userRole = userRole.trim();
        LastLoginRole = LastLoginRole.trim();
        let swithToview = '';
        if (userRole === 'admin' && LastLoginRole === 'employee') {
            swithToview = 'adminView';
        } else if (userRole === 'admin' && LastLoginRole === 'admin') {
            swithToview = 'employeeView';
        } else if (userRole === 'super-user' && LastLoginRole === 'super-user') {
            swithToview = 'employeeView';
        } else if (userRole === 'super-user' && LastLoginRole === 'employee') {
            swithToview = 'juryView';
        } else if (userRole === 'admin-superAdmin' && LastLoginRole === 'admin-superAdmin') {
            swithToview = 'adminSuperAdminView';
        } else if (userRole === 'admin-superAdmin' && LastLoginRole === 'admin') {
            swithToview = 'adminSuperAdminView';
        } else if (userRole === 'admin-superAdmin' && LastLoginRole === 'super-user') {
            swithToview = 'adminSuperAdminView';
        } else if (userRole === 'admin-superAdmin' && LastLoginRole === 'employee') {
            swithToview = 'adminSuperAdminView';
        }
        return swithToview;
    }

    static fileSizeErrorMsg(fileSizeLimit) {
        const file_size = `${fileSizeLimit}${NominationConstants.MB_FILE_UNIT}`;
        const msg = NominationUtility.createReadableMessage(
          NominationConstants.messages.ERROR.FILE_SIZE_ERROR, file_size);
        return msg;
      }
    
      static fileTypeErrorMsg(fileTypes) {
        return NominationUtility.createReadableMessage(
          NominationConstants.messages.ERROR.FILE_TYPE_ERROR, fileTypes);
      }
    static getAwardID(list, awardName) {
        const awardList = JSON.parse(list);
        let awardId = -1;
        awardList.map((item) => {
            console.log("item.award_name",item.award_name);
            if (NominationUtility.trimAllSpaces(item.award_name) == NominationUtility.trimAllSpaces(awardName)) {
                awardId = item.id;
            }
        });
        return awardId;
    }
}
