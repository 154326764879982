<nav class="navbar navbar-inverse dmi-home-nav header-responsive">
    <div class="container-fluid">
    </div>
</nav>
<div class="login-form form-responsive">
    <div class="message-wrapper">
        <div class="alert alert-success animate__animated animate__bounceIn" *ngIf="showSuccess">
            {{successMsg}}
        </div>
        <div class="alert alert-danger animate__animated animate__bounceIn" *ngIf="showError">
            {{errorMsg}}
        </div>
    </div>
    <form [formGroup]="employeeLogin" (ngSubmit)="doLogin()" autocomplete="email password">
        <div class="avatar">
            <img src="../../assets/img/dmi-login-header-1.png" alt="dmi-logo" srcset="" width=220px
             height=100px>
        </div>
        <h4 class="modal-title">Login with DMI Email</h4>
        <!-- <div class="text-center small sign-up-link">First time user? <a routerLink="/sign-up">Register here</a></div> -->
        <div class="form-group">
            <input type="text" autocomplete="email" formControlName="email" placeholder="Email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
        </div>
        <!-- <div class="form-group">
            <input type="password" autocomplete="password" formControlName="password" placeholder="Password"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
        </div>
        <div class="form-group small clearfix">
            <label class="checkbox-inline"><input type="checkbox"> Remember me</label>
            <a routerLink="/forget-password" class="forgot-link">Forgot Password?</a>
        </div> -->
        <input type="button" class="btn btn-primary btn-block btn-lg" (click)="doLogin()" value="Login">
    </form>
</div>