import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { HomeComponent } from './home/home.component';
import { EmployeeComponent } from './home/employee/employee.component';
import { TeamComponent } from './home/team/team.component';
import { NominateComponent } from './home/nominate/nominate.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TeamNominationComponent } from './home/team-nomination/team-nomination.component';
import { AuthGuard } from './security/auth.guard';
import { LazyLoadAuthGuard } from './security/lazy-load-auth.guard';
import { SuperAdminGuard } from './security/super-admin.guard';
import { NominationCompletedComponent } from './nomination-completed/nomination-completed.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignupComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'nominations-closed', component: NominationCompletedComponent },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: 'employee', pathMatch: 'full' },
      { path: 'employee', component: EmployeeComponent },
      { path: 'team', component: TeamComponent },
      { path: 'entries', component: NominateComponent },
      { path: 'team-entries', component: TeamNominationComponent },
      { path: 'logout', redirectTo: '/login', pathMatch: 'full' },
    ]
  },
  {
    path: 'jury-panel', canLoad: [SuperAdminGuard], loadChildren: () => import(`./jury-panel/jury-panel.module`).then(
      module => module.JuryPanelModule
    )
  },
  {
    path: 'admins', canLoad: [LazyLoadAuthGuard], loadChildren: () => import(`./admin-members/admin-members.module`).then(
      module => module.AdminMembersModule
    )
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
